<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="filter">
            <div class="left">
                <div class="item">
                    <div class="title">类型选择：</div>
                    <el-select v-model="classificationId" @change="getList" placeholder="请选择">
                        <el-option v-for="item in classificationList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="item">
                    <el-select @change="getList" v-model="startCore" placeholder="最低积分">
                        <el-option v-for="item in integralList" :disabled="endCore <= item.value" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="span">-</span>
                    <el-select @change="getList" v-model="endCore" placeholder="最高积分">
                        <el-option v-for="item in integralList" :disabled="item.value <= startCore" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="right">
                <div class="tabs">
                    <span :class="{ active: sort === 0 }" @click="sortChange(0)">
                        销量
                        <i :class="sort === 0 ? (sorts ? 'el-icon-arrow-up' : 'el-icon-arrow-down') : 'el-icon-arrow-down'"></i>
                    </span>
                    <span :class="{ active: sort === 1 }" @click="sortChange(1)">
                        积分值
                        <i :class="sort === 1 ? (sorts ? 'el-icon-arrow-up' : 'el-icon-arrow-down') : 'el-icon-arrow-down'"></i>
                    </span>
                    <span :class="{ active: sort === 2 }" @click="sortChange(2)">新品</span>
                </div>
                <el-input prefix-icon="el-icon-search" placeholder="请输入搜索内容" v-model="search" clearable></el-input>
            </div>
        </div>

        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="goDetail(item.id)">
                <img :src="item.picUrl" alt="" />
                <div class="title">{{ item.name }}</div>
                <div class="jifen">{{ item.exchangeScore }}积分</div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getClassificationList, getGoodsList } from '@/api';

export default {
    name: 'Collection',
    data() {
        return {
            types: 0, //类型 我能兑换 折扣专区 新品推荐
            sort: 0,
            sorts: 0,
            limit: 12,
            page: 1,
            total: 0,
            type: null,
            typeList: [
                { label: '男', value: '1' },
                { label: '女', value: '2' },
            ],
            integralStart: null,
            integralEnd: null,
            integralList: [
                { label: '1000', value: 1000 },
                { label: '2000', value: 2000 },
                { label: '3000', value: 3000 },
                { label: '4000', value: 4000 },
                { label: '5000', value: 5000 },
                { label: '6000', value: 6000 },
                { label: '7000', value: 7000 },
                { label: '8000', value: 8000 },
                { label: '9000', value: 9000 },
                { label: '10000', value: 10000 },
            ],
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '文化银行',
                    to: { path: '/bank' },
                },
                {
                    title: '折扣专区',
                },
            ],
            classificationId: null,
            endCore: 999999,
            startCore: 0,
            classificationList: [],
            list: [],
            time: null,
            search: null,
        };
    },
    watch: {
        search() {
            // this.time && clearTimeout(this.time);
            clearTimeout(this.time);
            this.time = setTimeout(() => {
                this.getList();
            }, 500);
        },
    },
    created() {
        let typeArr = ['我能兑换', '折扣专区', '新品推荐'];
        this.types = this.$route.query.types;
        if (this.types == 0) {
            this.endCore = JSON.parse(localStorage.getItem('userInfo')).score;
        }
        this.nav[2].title = typeArr[this.$route.query.types];

        this.getClassificationList();
    },
    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        sortChange(idx) {
            if (idx === this.sort) {
                this.sorts = !this.sorts;
            } else {
                this.sort = idx;
                this.sorts = 0;
            }
        },
        goDetail(id) {
            this.$router.push({
                path: '/bankDetail',
                query: {
                    id: id,
                },
            });
        },
        getClassificationList() {
            getClassificationList().then((res) => {
                let list = [];
                list.push({ label: '全部', value: null });
                if (res.code === 200) {
                    list.push(
                        ...res.data.map((item) => {
                            return {
                                label: item.name,
                                value: item.id,
                            };
                            // console.table({ label: item.name, value: item.id });
                        }),
                    );
                    this.classificationList = list;
                    // console.table({  });
                    // console.log(this.classificationList);
                }
                this.getList();
            });
        },

        getList() {
            getGoodsList({
                // type: this.types,
                endCore: this.endCore,
                startCore: this.startCore,
                classificationId: this.classificationId,
                pageNum: this.page,
                pageSize: this.limit,
                name: this.search,
            }).then((res) => {
                console.table({ res });
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .is-active {
    background: white !important;
}

::v-deep .el-date-editor--daterange.el-input__inner {
    width: max-content;
}

.filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left {
        display: flex;

        .item {
            display: flex;
            align-items: center;

            .title {
                display: flex;
                width: max-content;
            }

            .el-select {
                width: 160px;
                margin-right: 24px;

                .el-input__inner {
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-size: 14px;
                    line-height: 17px;
                    color: #606266;
                }
            }

            .span {
                margin-right: 24px;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;

        .tabs {
            display: flex;
            align-items: center;
            overflow: hidden;
            border-radius: 3px;
            cursor: pointer;
            margin-right: 24px;

            span {
                padding: 6.5px 10px;
                font-size: 14px;
                line-height: 17px;
                flex: none;
                order: 0;
                flex-grow: 0;
                background: #fff;
                border-width: 1px 1px 1px 1px;
                border-style: solid;
                border-color: #dcdfe6;
            }

            .active {
                color: #fff;
                background: #eda200;
                border-color: #eda200;
            }
        }

        ::v-deep .el-input {
            width: 240px;
            height: 30px;
            border-radius: 50px;
        }

        ::v-deep .el-input__inner {
            border-radius: 20px;
            height: 30px;
        }

        ::v-deep .el-input__icon {
            line-height: 30px;
            color: #eda200;
        }
    }
}

.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;

    .item {
        margin-top: 24px;
        background: #ffffff;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid #ebebeb;

        img {
            width: 100%;
            height: 210px;
            object-fit: cover;
        }

        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            height: 60px;
            width: 100%;
            color: #333333;
            padding: 12px;
            .ellipsisLine(2);
        }

        .jifen {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            color: #eda200;
            margin-top: 16px;
            padding: 0 0 12px 12px;
        }
    }
}
</style>
